/* ==========================================================================
  main.js
========================================================================== */

'use strict';

/* global Tobii, theme_strings_lightbox, tns */

const initLightbox = () => {
  if (document.querySelectorAll('.lightbox').length > 0) {
    try {
      new Tobii({
        navLabel: [theme_strings_lightbox.prev, theme_strings_lightbox.next],
        closeLabel: theme_strings_lightbox.close,
        loadingIndicatorLabel: theme_strings_lightbox.loading,
        captionsSelector: 'self',
        captionAttribute: 'data-caption',
        zoom: false,
      });
    } catch (e) {
      console.log(e);
    }
  }
};

const initSliderProduct = () => {
  // Home introduction slider
  if (document.querySelectorAll('.slider-product--active').length) {
    const homeSlider = tns({
      container: '.slider-product--active',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      controlsContainer: '.slider-product__controls',
      controls: false,
      navPosition: 'bottom',
      autoHeight: true,
      responsive: {
        1080: {
          controls: true,
          nav: false,
          autoHeight: false,
        },
      },
    });
  }
};

const initFooterSlider = () => {
  // Footer Slider
  if (document.querySelectorAll('.footer-slider__images--active').length) {
    const footerSlider = tns({
      container: '.footer-slider__images--active',
      items: 1,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayButtonOutput: false,
      rewind: false,
      swipeAngle: false,
      prevButton: '#footer-sliderPrev',
      nextButton: '#footer-sliderNext',
      autoWidth: true,
      gutter: 0,
      responsive: {
        720: {
          items: 2,
          nav: false,
          controls: true,
          gutter: 40,
        },
      },
    });
  }
};

/**
 * Vanilla JS Smooth scrolling
 * @source : https://webdesign.tutsplus.com/tutorials/smooth-scrolling-vanilla-javascript--cms-35165
 */
function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute('href');
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: 'smooth',
  });
}

/**
 * Init lightbox
 */
document.addEventListener('DOMContentLoaded', () => {
  initLightbox();
  initSliderProduct();
  initFooterSlider();

  const links = document.querySelectorAll('.smooth-scroll');

  if (links.length > 0) {
    for (const link of links) {
      link.addEventListener('click', clickHandler);
    }
  }
});
